/* (로고) 한인타운 사람들 */
@font-face {
    src: url('fonts/TmoneyRoundWindExtraBold.woff') format('woff');
    font-family: TmoneyRoundWindExtraBold;
}
/* News */
@font-face {
    src: url('fonts/BareunBatangOTFM.woff') format('woff');
    font-family: BareunBatangOTFM;
}
/* Story */
@font-face {
    src: url('fonts/Kalguksu.woff') format('woff');
    font-family: Kalguksu;
}
/* AD */
@font-face {
    src: url('fonts/GyeonggiBatang.woff') format('woff');
    font-family: GyeonggiBatang;
}

body{
    color: #2f2f2f;
    background-color: #f9f7f1;
}
header{
    font-family: 'TmoneyRoundWindExtraBold', serif;
    font-weight: 900;
    font-size: 80px;
    text-transform: uppercase;
    display: inline-block;
    line-height: 82px;
    margin-top: 10px;
    margin-bottom: 20px;
}
header div{
    display: inline-block;
}
p{
    margin-top: 0;
    margin-bottom: 20px;
}
.head{
    text-align: center;
    position: relative;
}
.subhead{
    text-transform: uppercase;
    border-bottom: 2px solid #2f2f2f;
    border-top: 2px solid #2f2f2f;
    padding: 12px 0 12px 0;
}
.quote{
    position: relative;
    font-size: 14px;
    width: 14%;
    left: 10px;
    border: 3px double #2f2f2f;
    padding: 10px 15px 10px 15px;
    line-height: 20px;
    display: inline-block;
    margin: 0 50px 20px -360px;
}
.content{
    font-size: 0;
    line-height: 0;
    word-spacing: -.31em;
    display: inline-block;
    margin: 30px 2% 0 2%;
}
.column{
    line-height: 1.5;
    width: 17.5%;
    display: inline-block;
    padding: 0 1% 0 1%;
    vertical-align: top;
    transition: all .7s;
}
.column + .column {
    border-left: 1px solid #2f2f2f;
}

/* Tick */
.column .tick{
    font-family: serif;
    position: absolute;
    font-size: 12px;
    line-height: 1.3;
    padding: 0px 5px;
}
.column .tick.ad{
    background-color: black;
    color: white;
}
.column .tick.story{
    background-color: #666;
    color: white;
}
.column .headline{
    text-align: center;
    line-height: normal;
    display: block;
    margin: 0 auto;
}

/* column headline */
.column .headline.hl{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 36px;
    box-sizing: border-box;
    padding: 20px 0;
}
.column .headline.sub_hl{
    font-weight: 400;
    font-size: 22px;
    box-sizing: border-box;
    padding: 20px 0;
}
.column .headline.sub_hl:before{
    border-top: 1px solid #2f2f2f;
    content: '';
    width: 100px;
    height: 7px;
    display: block;
    margin: 0 auto;
}
.column .headline.sub_hl:after{
    border-bottom: 1px solid #2f2f2f;
    content: '';
    width: 100px;
    height: 10px;
    display: block;
    margin: 0 auto;
}

/* News */
.column.news {
    font-family: 'BareunBatangOTFM', serif;
}
.column.news .headline.hl{
    font-style: italic;
}

/* Story */
.column.story {
    font-family: Kalguksu;
}
.column.story .headline.hl{
    font-size: 42px;
    font-style: italic;
}
.column.story .details{
    font-size: 22px;
    line-height: 1.1;
    padding: 10px 0 10px 0;
}

/* Ad */
.column.ad {
    font-family: GyeonggiBatang;
}

.media {
    -webkit-filter: sepia(80%) contrast(1) opacity(0.8);
    filter: sepia(80%) grayscale(1) contrast(1) opacity(0.8);
    mix-blend-mode: multiply;
    width: 100%;
    border: 1px solid #555;
}
.figcaption{
    font-family: 'BareunBatangOTFM', serif;
    font-style: italic;
    font-size: 10px;
    line-height: 1;
}

@media only all and (max-width: 1300px) {
    .quote {
        display: none;
    }
}
 @media only all and (max-width: 1200px) {
    .column{
        width: 31%;
    }
}
@media only all and (max-width: 1000px) {
    .column{
        width: 47%;
    }
}
@media only all and (max-width: 700px) {
    .column{
        width: 100%;
        border-left: none;
        padding-bottom: 20px;
    }
    .column + .column {
        border-top: 1px solid #2f2f2f;
        border-left: none;
    }
    header{
        max-width: 320px;
        font-size: 60px;
        line-height: 62px;
        overflow: hidden;
    }
}
@media only all and (max-width: 400px) {
    .column {
        font-size: 18px;
    }
    .column.story .headline.sub_hl{
        font-size: 26px;
    }
    .column.story .details{
       font-size: 26px;
    }
}
